// vue
import { h, ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useConfirm } from '@store/confirm';

// services
import BotsService from'@services/bots';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();
    const confirm = useConfirm();

    // vars
    const botFullInfo = ref();
    const percentage = ref(0);
    const countDone = ref(0);
    const loadings = reactive({
        modal: false,
        button: false,
    });

    // i18n
    const { t } = useI18n();

    // computeds
    const disabled = computed(() => copiesBotModel.count == -1);
    const percent = computed(() => 100 / copiesBotModel.count);

    // watches
    watch(() => props.botId, v => {
        if (v) {
            getFullInfo(v);
        } else {
            copiesBotModel.count = 1;
            countDone.value = 0;
            percentage.value = 0;
        }
    });

    const copiesBotModel = reactive({
        label: computed(() => (props.localization?.['enter_the_number_of_copies_of_the_bot'] || 'Введите количество копий бота') + ':'),
        count: 1,
        options: (() => {
            const result = [];

            for (let i = 1; i <= 25; i++) {
                result.push({
                    label: i,
                    value: i,
                });
            }

            return result;
        })(),
    });

    const getFullInfo = async id => {
        loadings.modal = true;

        try {
            const result = await BotsService.getFullInfo(id);

            if (!result.data.status) {
                result.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
            } else {
                botFullInfo.value = result.data.record;
            }
        } catch  {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
    
        loadings.modal = false;
    };

    const makeid = length => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };
  

    const onCreateCopies = async () => {
        loadings.button = true;

        const cid = makeid(3);
        const payloadList = [];

        for (let i = 1; i <= copiesBotModel.count; i++) {
            const payload = {
                ...botFullInfo.value,
                name: botFullInfo.value.name + ` ${i} (cid: ${cid})`,
            };

            delete payload.id;

            await botsAddNew(payload);

            // payloadList.push(botsAddNew(payload));
        }

        // await Promise.allSettled(payloadList);

        gl.showNotification({
            type: 'success',
            msg: `${copiesBotModel.count} ` +(props.localization?.['n_bots_were_successfully_created'] || 'бота(-ов) были успешно созданы'),
        });

        loadings.button = false;

        // close modal
        context.emit('update:botId', null);
    };

    const botsAddNew = async payload => {
        try {
            bots.clearErrors();

            const result = await BotsService.addNew({
                pager: CRUD_PAGER_FACTORY(),
                records: [payload],
            });

            if (result.postMessages) {
                // 
            };

            if (!result.data.status) {
                // shit
            } else {
                // done
                percentage.value += percent.value;
                countDone.value += 1;

                context.emit('oneBotIsDone', result.data.records[0]);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    return {
        gl,
        bots,
        percent,
        disabled,
        loadings,
        countDone,
        percentage,
        copiesBotModel,
        onCreateCopies,
    };

}