<template>
<div class="flex items-center">
    <n-checkbox
        class="flex rounded-md items-center"
        :size="size"
        :checked="checked"
        @update:checked="$emit('update:checked', $event)">
            <div class="text-left" :class="labelClasses">{{ label }}</div>
    </n-checkbox>
    <n-popover
        v-if="help"
        scrollable
        trigger="click"
        class="rounded-md max-w-[200px] max-h-[200px]"
        :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'">
        <template #trigger>
            <n-icon
                class="cursor-pointer text-lg mx-2"
                :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                <QuestionCircle20Filled />
            </n-icon>
        </template>
        <span
            class="notes text-[10px] text-white"
            v-html="help">
        </span>
    </n-popover>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NPopover,
    NCheckbox } from 'naive-ui';

// icons
import { QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-checkbox',
    emits: ['update:checked'],
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop), 
        },
        checked: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        help: {
            type: String,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
        popoverType: {
            type: String,
            default: 'info',
            validator: prop => ['info', 'warning'].includes(prop), 
        },
    },
    components: {
        NIcon,
        NPopover,
        NCheckbox,
        QuestionCircle20Filled,
    },
    setup(props) {
        const labelClasses = computed(() => ({
            'whitespace-nowrap': props.nowrap,
            'text-[10px]': props.size == 'tiny',
            'text-xs': props.size == 'medium',
        }));

        return {
            labelClasses,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-checkbox-box) {
    border-radius: 4px;
}
</style>