<template>
<base-drawer-mobile
  :label="localization?.['available_bots_table_mass_more_clone']"
  :show="!!botId"
  :loading="loadings.modal"
  @update:show="!$event ? $emit('update:botId', null) : null">
  <template #default>
      <rb-select
          :label="copiesBotModel.label"
          label-position="left"
          :disabled="loadings.button"
          :options="copiesBotModel.options"
          v-model:value="copiesBotModel.count" />
      
      <section class="mt-4">
          <div class="text-gray-400 dark:text-white/50 text-xs text-right">
              {{ countDone }} / {{ copiesBotModel.count != -1 ? copiesBotModel.count : 1 }}
          </div>
          <n-progress :height="4" class="mt-2" type="line" :percentage="percentage" :show-indicator="false" />
      </section>
  </template>
  <template #footer>
      <n-button
          class="text-white/90 rounded-md w-full"
          :disabled="disabled || loadings.button"
          :loading="loadings.button"
          :color="gl.mainColor"
          @click="onCreateCopies">
          {{ localization?.['create_copies'] || 'Создать копии' }}
      </n-button>
  </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NButton,
    NProgress } from 'naive-ui';

export default {
    name: 'bot-clone-more-mobile',
    props: {
        botId: {
            type: [String, Number],
            default: '',
        },
        localization: {
            type: Object,
            required: true,
        },
    },
    components: {
        NButton,
        NProgress,
    },
    setup(props, context) {
    
        return {
            ...general(...arguments),
        };
    },
};
</script>