import { defineStore } from 'pinia';

export const useConfirm = defineStore('confirm', {
    state: () => ({
        show: false,
        title: '',
        fn: null,
        args: null,
        type: 'text',
        declineMessage: '',
        confirmLabel: '',
        genConfCheck: false,
    }),
    getters: {
        showFooter(state) {
            return !!state.declineMessage || !!state.confirmLabel;
        },
        showCheck(state) {
            return state.type == 'confirm_check';
        },
    },
    actions: {
        openConfirm({
            title = '',
            fn,
            args,
            type = 'text',
            declineMessage = '',
            confirmLabel = '',
        }) {
            this.show = true;
            this.title = title;
            this.fn = fn;
            this.args = args;
            this.type = type;
            this.declineMessage = declineMessage;
            this.confirmLabel = confirmLabel;
        },
        closeConfirm() {
            this.show = false;

            setTimeout(() => {
                this.clearData();
            }, 200);
            
        },
        clearData() {
            this.show = false;
            this.title = '';
            this.fn = null;
            this.args = null;
            this.type = 'text';
            this.declineMessage = '';
            this.confirmLabel = '';
            this.genConfCheck = false;
        },
        doFn() {
            this.show = false;
            this.fn(this.args);
        },
    },
});