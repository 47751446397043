<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex flex-wrap justify-center items-center"
    :class="[classes, actionsPrepare.length > 3 ? 'w-[140px]' : 'w-full']">
    <n-popover
        v-for="(item, i) in actionsPrepare.slice(0, 3)"
        :key="i"
        placement="top"
        class="bg-main"
        :arrow-style="{
            backgroundColor: gl.mainColor,
        }">
        <template #trigger>
            <div @click="item.fn(row)" class="p-2">
                <n-icon class="inline cursor-pointer text-xl text-current" :class="`text-${colors[item.id]}`">
                    <component :is="item.icon(row)"></component>
                </n-icon>
            </div>
        </template>
        <template #default>
            <div class="text-white bg-main text-xs" v-html="item.popover"></div>
        </template>
    </n-popover>

    <n-dropdown
        v-if="actionsPrepareMoreOptions.length"
        trigger="hover"
        class="bg-white dark:bg-darkCardBg"
        :options="actionsPrepareMoreOptions"
        @select="handleSelect">
        <n-button
            strong
            secondary
            class="px-1 h-full rounded-md">
            <span class="font-semibold text-base -mt-2">...</span>
        </n-button>
    </n-dropdown>

    <base-modal-desktop
        v-model:show="showConfirmationCheck">
        <template #default>
            <div class="text-md text-gray-600 dark:text-white/75">{{ declineMessage }}</div>
        </template>
        <template #footer>
            <div class="flex items-center justify-end">
                <rb-checkbox
                    :label="'- ' + confirmLabel"
                    v-model:checked="genConfCheck" />

                <n-button
                    strong
                    class="ml-3 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!genConfCheck"
                    @click="showConfirmationCheck = false, onGeneralConfirmClicked('start')">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </div>
        </template>
    </base-modal-desktop>

    <base-dialog-desktop
        v-model:show="showConfirmation"
        @positive-click="showConfirmation = false, onGeneralConfirmClicked(action)"
        @negative-click="showConfirmation = false">
        <div class="text-md" v-html="refs.localization.confirmations.bot[action]"></div>
    </base-dialog-desktop>
</div>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NButton,
    NPopover,
    NDivider,
    NDropdown } from 'naive-ui';

// components
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';

export default {
    name: 'cell-actions',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: 'marketplace.buy',
        },
        classes: {
            type: String,
        },

    },
    components: {
        NIcon,
        NButton,
        RbSelect,
        NPopover,
        NDivider,
        BaseModal,
        NDropdown,
        RbCheckbox,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>