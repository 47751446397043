// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';
import { useConfirm } from '@store/confirm';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// services
import ProfileService from '@services/profileService';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute } from 'vue-router';

// naive-ui
import {
    useNotification } from 'naive-ui';

// icons
import { DeleteDismiss24Regular } from '@vicons/fluent';

export default function (props) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const profile = useProfile();

    // ui
    const notification = useNotification();

    // router
    const route = useRoute();

    // i18n
    const { t } = useI18n();

    const confirm = useConfirm();

    // vars
    const { _ } = window;
    const loading = ref(false);

    watch(() => props.tab, v => {
        if (props.myTab === v && !gl.isMobile) {
            if (!dataRefs.value)
                oauthProviders();
        }
    });

    const id_provider = ref(-1);
    const dataRefs = ref();
    const filters = reactive(CRUD_PAGER_FACTORY());

    const actions = computed(() => [{
        id: 'q_auth__delete',
        icon: row => DeleteDismiss24Regular,
        fn: row => doSomething(['delete', row.id]),
        popover: row => profile.localization?.['profile/oauth/grid/actions/unlink'],
    }]);

    const oauthProvidersOptions = computed(() => profile.oauthProviders.map(el => ({
        label: el.title,
        value: +el.id,
        ...el,
    })));

    const provider_bind= computed(() => {
        if (id_provider.value == -1) return false;
        return profile.oauthProviders.find(({ id }) => id == id_provider.value);
    });

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && dataRefs.value?.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            oauthProviders();
    };

    const updateDate = $event => {
        if ($event) {
            let value = `${new Date($event[0]).toISOString().split('T')[0]} ${new Date($event[1]).toISOString().split('T')[0]}`;
            changeFilter(['filters.period', value ]);
        }
    };

    const oauthProviders = async (showLoading = true) => {
        if (showLoading)
            gl.isMobile
                ? loading.value = true
                : gl.showLoading = true;

        try {
            dataRefs.value = ( await ProfileService.oauthProviders(filters) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        if (showLoading)
            gl.isMobile
                ? loading.value = false
                : gl.showLoading = false;
    };

    const onOauthProvidersDelete = async curIdProvider => {
        gl.isMobile
            ? loading.value = true
            : gl.showLoading = true;

        try {
            const { data, postMessages } = await ProfileService.oauthProvidersDelete([ curIdProvider ]);

            if (postMessages)
                postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

            if (data.status) {
                await oauthProviders(false);
            }
              
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        gl.isMobile
            ? loading.value = false
            : gl.showLoading = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            delete: () => {
                confirm.openConfirm({
                    title: refs.localization.confirmations.oauth.delete,
                    fn: () => onOauthProvidersDelete(value),
                });
            },
        };

        fns[action]();
    };

    const openLink = link => {
        window.open(link, '_blank');
    };

    onMounted(async () => {
        if (gl.isMobile) {
            if (!dataRefs.value)
                oauthProviders();
        } else {
            if (route.query?.tab === 'oauth')
                oauthProviders();
        }
    });

    return {
        gl,
        refs,
        loading,
        profile,
        filters,
        actions,
        dataRefs,
        id_provider,
        provider_bind,
        oauthProvidersOptions,
        openLink,
        doSomething,
        updateDate,
        changeFilter,
        oauthProviders,
        onOauthProvidersDelete,
    };
}