<template>
<n-modal
    class="rounded-lg bg-white dark:bg-darkCardBg p-0"
    :show="show"
    transform-origin="center"
    @update:show="!$event ? $emit('update:show', $event) : null">
    <n-card
        size="small"
        role="dialog"
        aria-modal="true"
        header-style="padding: 8px 12px;"
        class="rounded-lg"
        :class="[classes, lightDark ? 'bg-white dark:bg-darkCardBg' : 'bg-white dark:bg-darkbg']"
        :segmented="{
          content: true,
          footer: 'soft'
        }"
        style="width: 420px;"
        content-style="padding: 0;">
        <template #default>
            <section class="p-4">
                <n-icon
                    class="cursor-pointer text-2xl text-main">
                    <ChatWarning24Filled />
                </n-icon>
                <slot name="default"></slot>
                <slot name="body"></slot>
            </section>
            <n-divider class="my-0" />
            <section class="p-3">
                <div class="flex justify-end items-center">
                    <slot name="footerCheck"></slot>
                    <n-button
                        secondary
                        size="small"
                        type="default"
                        class="mx-2 rounded-md text-gray-600 dark:text-white/75"
                        @click="$emit('negative-click')">
                        {{ refs.localization.dialogs.cancel }}
                    </n-button>
                    <n-button
                        secondary
                        size="small"
                        type="default"
                        class="rounded-md bg-main text-white/90"
                        :disabled="disabled"
                        @click="$emit('positive-click')">
                        {{ refs.localization.dialogs.ok }}
                    </n-button>
                </div>
            </section>
        </template>
    </n-card>
</n-modal>
</template>

<script>
// store
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton,
    NDivider } from 'naive-ui';

// icons
import { Dismiss16Filled, ChatWarning24Filled } from '@vicons/fluent';

export default {
    name: 'base-dialog-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number, String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        NDivider,
        ChatWarning24Filled,
        Dismiss16Filled,
    },
    setup() {
        // store
        const refs = useRefs();

        return {
            refs,
        };
    },
};
</script>